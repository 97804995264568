import extend from 'xtend';
import videojs from 'video.js';
import { on } from 'delegated-events';
import { getElementByHashLink, sanitizeElementData } from '@ts-utilities/base';
const allowedModalLinkKeys = [
    'notitle', 'nopadding', 'iframe',
    'width', 'height'
];
const videojsOptions = {
    controlBar: {
        children: [
            'playToggle',
            'progressControl',
            'volumePanel',
            'fullscreenToggle'
        ]
    }
};
export function bootstrapModalTypes() {
    on('click', '.modalLink, .popupLink_inline, .modalLink_inline', openModalNormal);
    on('click', '.popupLink_iframe, .modalLink_iframe', openModalIFrame);
    on('click', '.popupLink_videoInline_sc, .modalLink_videoInline_sc, .popupLink_videoInline, .modalLink_videoInline', openModalVideo);
    on('click', '.popupLink_video_sc, .modalLink_video_sc, .popupLink_video, .modalLink_video', openModalVideo);
}
function showModal(title, content, options) {
    if (!window.M.theme_soon.events) {
        throw new Error("Can't open modal, no event emitter registered!");
    }
    window.M.theme_soon.events.emit('MODAL_OPEN', Object.assign({ title, content }, options));
}
function openModalNormal(e) {
    e.preventDefault();
    const link = e.target;
    let title = link.getAttribute('title') || link.textContent;
    const contentElem = getElementByHashLink(link);
    if (!contentElem) {
        throw new Error(`Modal: Missing content element!`);
    }
    const content = contentElem.innerHTML;
    const dataOptions = sanitizeElementData(link, {
        allowedKeys: allowedModalLinkKeys,
        sanitizeKeyFunc: key => key.replace(/options/, '').toLowerCase()
    });
    if (contentElem && contentElem.dataset && contentElem.dataset.title) {
        title = contentElem.dataset.title;
    }
    const defaultOptions = {
        html: true,
    };
    const options = extend({}, defaultOptions, dataOptions);
    showModal(title, content, options);
}
function openModalVideo(e) {
    var _a;
    e.preventDefault();
    let targetElem = e.target;
    const title = targetElem.textContent;
    let content = null;
    if (!(targetElem instanceof HTMLLinkElement)) {
        targetElem = targetElem.closest('a');
    }
    if (!targetElem) {
        console.error('No source link element found!');
    }
    const linkElem = targetElem;
    const dataOptions = sanitizeElementData(targetElem, {
        allowedKeys: allowedModalLinkKeys,
        sanitizeKeyFunc: key => key.replace(/options/, '').toLowerCase(),
    });
    let videojsInst;
    const source = ((_a = linkElem.dataset) === null || _a === void 0 ? void 0 : _a.url) ?
        atob(linkElem.dataset.url) : linkElem.href;
    const defaultOptions = {
        type: 'video',
        source,
        nopadding: true,
        onOpen: (element) => {
            const videoElement = element.querySelector('video');
            if (!videoElement) {
                throw new Error('No video element found in modal content!');
            }
            videojsInst = videojs(videoElement, videojsOptions);
            videojsInst.ready(() => videojsInst.play());
        },
        onClose: () => {
            if (videojsInst) {
                videojsInst.dispose();
            }
        },
        width: 850
    };
    const options = extend({}, defaultOptions, dataOptions);
    showModal(title, content, options);
}
function openModalIFrame(e) {
    e.preventDefault();
    const link = e.target;
    const title = link.textContent;
    const iframeSrc = link.href;
    if (!iframeSrc) {
        return;
    }
    const dataOptions = sanitizeElementData(link, {
        allowedKeys: allowedModalLinkKeys,
        sanitizeKeyFunc: key => key.replace(/options/, '').toLowerCase()
    });
    const defaultOptions = {
        type: 'iframe',
        iframe: true,
        nopadding: true,
        width: 1000,
        height: 677
    };
    const options = extend({}, defaultOptions, dataOptions);
    showModal(title, iframeSrc, options);
}
